<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <input type="file" @change="onFileChange">
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" v-for="image in images" :key="image.name">
                <v-card>
                    <v-img :src="image.url" max-width="200"></v-img>
                    <v-card-text>
                        {{ image.url }}
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="red" @click="deleteImage(image)">Удалить</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import fire from "@/main";

export default {
    name: 'Images',
    data() {
        return {
            images: [],
        };
    },
    methods: {
        onFileChange(e) {
            const file = e.target.files[0];
            this.uploadImage(file);
        },
        async uploadImage(file) {
            const storageRef = fire.storage().ref(`admin/${file.name}`).put(file);
            storageRef.on('state_changed',
                (snapshot) => {
                    console.log(snapshot.bytesTransferred);
                    // Опционально: отслеживание прогресса загрузки
                },
                (error) => {
                    console.error(error);
                },
                () => {
                    storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        this.images.push({ name: file.name, url: downloadURL });
                    });
                }
            );
        },
        fetchImages() {
            const storageRef = fire.storage().ref('admin/');
            storageRef.listAll().then((result) => {
                result.items.forEach((imageRef) => {
                    imageRef.getDownloadURL().then((url) => {
                        this.images.push({ name: imageRef.name, url: url });
                    });
                });
            }).catch((error) => {
                console.error(error);
            });
        },
        async deleteImage(image) {
            // Удаляем файл из Firebase Storage
            const fileRef = fire.storage().refFromURL(image.url);
            try {
                await fileRef.delete();
                // Удаляем изображение из списка, если файл успешно удалён
                this.images = this.images.filter(img => img.url !== image.url);
                this.$emit('deleted', image.name); // Опционально: можно использовать для уведомления пользователя
            } catch (error) {
                console.error("Ошибка удаления файла: ", error);
                // Опционально: обработка ошибок, например, показ сообщения пользователю
            }
        }
    },
    mounted() {
        this.fetchImages();
    }
}
</script>
  